import en from "@/assets/locales/en.json"
import cs from "@/assets/locales/cs.json"

export const languages = {
    cs: "Čeština",
    en: "English",
}

export const defaultLang = "cs"

export const ui = { cs, en } as const
